import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "laitdsm",
		component: () => import("@/views/LaitDSMComponents.vue"),
	},
	{
		path: "/komponenter/",
		name: "komponenter",
		component: () => import("@/views/ComponentsView.vue"),
	},
	{
		path: "/animations/",
		name: "animations",
		component: () => import("@/views/AnimationsViews.vue"),
	},

	/* Components */
	{
		path: "/components/accordionBlock01A/",
		name: "accordionBlock01A",
		component: () => import("../views/components/AccordionView/AccordionView.vue"),
		meta: {
			title: "accordionBlock01A",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/AccordionItem/",
		},
	},
	{
		path: "/components/dropdown-single-select/",
		name: "dropdown single select",
		component: () => import("../views/components/DropdownSingleView/DropdownSingleView.vue"),
		meta: {
			title: "Dropdown Single Select",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/DropdownSingleSelect/",
		},
	},
	{
		path: "/components/dropdown-multi-select/",
		name: "dropdown multi select",
		component: () => import("../views/components/DropdownMultiView/DropdownMultiView.vue"),
		meta: {
			title: "Dropdown Multi Select",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/DropdownMultiSelect/",
		},
	},
	{
		path: "/components/dropdown-search-select/",
		name: "dropdown search select",
		component: () => import("../views/components/DropdownSearchView/DropdownSearchView.vue"),
		meta: {
			title: "Dropdown Search Select",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/DropdownSearchSingleSelect/",
		},
	},
	{
		path: "/components/skeleton-loader/",
		name: "skeleton loader",
		component: () => import("../views/components/SkeletonLoaderView/SkeletonLoaderView.vue"),
		meta: {
			title: "Skeleton loader",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/views/components/SkeletonLoaderView/",
		},
	},
	{
		path: "/components/videoBlock01A/",
		name: "videoBlock01A",
		component: () => import("../views/components/videoBlock01AView/videoBlock01AView.vue"),
		meta: {
			title: "videoBlock01A",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/VideoBlock/",
		},
	},
	{
		path: "/components/contactBanner01A/",
		name: "contactBanner01A",
		component: () => import("../views/components/contactBanner01A/contactBanner01AView.vue"),
		meta: {
			title: "contactBanner01A",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/ContactBanner/",
		},
	},
	{
		path: "/components/contentBlock01A/",
		name: "contentBlock01A",
		component: () => import("../views/components/contentBlock01AView/contentBlock01AView.vue"),
		meta: {
			title: "contentBlock01A",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/50-50-Block/",
		},
	},
	{
		path: "/components/contentBlock03A/",
		name: "contentBlock03A",
		component: () => import("../views/components/contentBlock03AView/contentBlock03AView.vue"),
		meta: {
			title: "contentBlock03A",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/contentBlock03A/",
		},
	},
	{
		path: "/components/accordion-item/",
		name: "accordion-item",
		component: () => import("../views/components/AccordionItemView/AccordionItemView.vue"),
		meta: {
			title: "Accordion Item",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/AccordionItem/",
		},
	},
	{
		path: "/components/testimonialSlider01A/",
		name: "testimonialSlider01A",
		component: () => import("../views/components/testimonialSlider01AView/testimonialSlider01AView.vue"),
		meta: {
			title: "testimonialSlider01A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/Testimonial/",
		},
	},
	{
		path: "/components/contentSlider01A/",
		name: "contentSlider01A",
		component: () => import("../views/components/contentSlider01AView/contentSlider01AView.vue"),
		meta: {
			title: "contentSlider01A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/Testimonial/",
		},
	},
	{
		path: "/components/contentSlider01B/",
		name: "contentSlider01B",
		component: () => import("../views/components/contentSlider01BView/contentSlider01BView.vue"),
		meta: {
			title: "contentSlider01B",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/Testimonial/",
		},
	},
	{
		path: "/components/promotionBlock01A/",
		name: "promotionBlock01A",
		component: () => import("../views/components/promotionBlock01AView/promotionBlock01AView.vue"),
		meta: {
			title: "promotionBlock01A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/Testimonial/",
		},
	},
	{
		path: "/components/promotionBlock02A/",
		name: "promotionBlock02A",
		component: () => import("../views/components/promotionBlock02AView/promotionBlock02AView.vue"),
		meta: {
			title: "promotionBlock02A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/Testimonial/",
		},
	},
	{
		path: "/components/downloadCard01A/",
		name: "downloadCard01A",
		component: () => import("../views/components/downloadCard01AView/downloadCard01AView.vue"),
		meta: {
			title: "downloadCard01A",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/contactCard01A/",
		},
	},
	{
		path: "/components/contentCard01A/",
		name: "contentCard01A",
		component: () => import("../views/components/contentCard01AView/contentCard01AView.vue"),
		meta: {
			title: "contentCard01A",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/contentCard01A/",
		},
	},
	{
		path: "/components/contentCard01B/",
		name: "contentCard01B",
		component: () => import("../views/components/contentCard01BView/contentCard01BView.vue"),
		meta: {
			title: "contentCard01B",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/contentCard01B/",
		},
	},
	{
		path: "/components/contactCard01A/",
		name: "contactCard01A",
		component: () => import("../views/components/contactCard01AView/contactCard01AView.vue"),
		meta: {
			title: "contactCard01A",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/contactCard01A/",
		},
	},
	{
		path: "/components/contactCard01B/",
		name: "contactCard01B",
		component: () => import("../views/components/contactCard01BView/contactCard01BView.vue"),
		meta: {
			title: "contactCard01B",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/contactCard01B/",
		},
	},
	{
		path: "/components/contentCard02A/",
		name: "contentCard02A",
		component: () => import("../views/components/contentCard02AView/contentCard02AView.vue"),
		meta: {
			title: "contentCard02A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/ContentCard/",
		},
	},
	{
		path: "/components/contentCard04A/",
		name: "contentCard04A",
		component: () => import("../views/components/contentCard04AView/contentCard04AView.vue"),
		meta: {
			title: "contentCard04A",
			type: "Komponent",
		},
	},
	{
		path: "/components/contentCard05A/",
		name: "contentCard05A",
		component: () => import("../views/components/contentCard05AView/contentCard05AView.vue"),
		meta: {
			title: "contentCard05A",
			type: "Komponent",
		},
	},
	{
		path: "/components/newsCard01B/",
		name: "newsCard01B",
		component: () => import("../views/components/newsCard01BView/newsCard01BView.vue"),
		meta: {
			title: "newsCard01B",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/newsCard01B/",
		},
	},
	{
		path: "/components/newsCard01A/",
		name: "newsCard01A",
		component: () => import("../views/components/newsCard01AView/newsCard01AView.vue"),
		meta: {
			title: "newsCard01A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/newsCard01A/",
		},
	},
	{
		path: "/components/newsCard02A/",
		name: "newsCard02A",
		component: () => import("../views/components/newsCard02AView/newsCard02AView.vue"),
		meta: {
			title: "newsCard02A",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/newsCard01A/",
		},
	},
	{
		path: "/components/newsCard02B/",
		name: "newsCard02B",
		component: () => import("../views/components/newsCard02BView/newsCard02BView.vue"),
		meta: {
			title: "newsCard02B",
			type: "Komponent",
			description: "",
			sourceLink: "https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/newsCard01A/",
		},
	},
	{
		path: "/components/breadCrumb01/",
		name: "breadCrumb01",
		component: () => import("../views/components/breadCrumb01View/breadCrumb01View.vue"),
		meta: {
			title: "breadCrumb01",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/BreadcrumbItem/",
		},
	},
	{
		path: "/components/dropdown-simple/",
		name: "dropdown simple",
		component: () => import("../views/components/DropdownSimpleView/DropdownSimpleView.vue"),
		meta: {
			title: "Dropdown Simple (Expand/collapse)",
			type: "Komponent",
			description: "",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/DropdownSimple/",
		},
	},
	{
		path: "/components/fillButton01A/",
		name: "fillButton01A",
		component: () => import("../views/components/fillButton01AView/fillButton01AView.vue"),
		meta: {
			title: "fillButton01A",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/fillButton01A/",
		},
	},
	{
		path: "/components/fillButton01B/",
		name: "fillButton01B",
		component: () => import("../views/components/fillButton01BView/fillButton01BView.vue"),
		meta: {
			title: "fillButton01B",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/fillButton01B/",
		},
	},
	{
		path: "/components/outlineButton01A/",
		name: "outlineButton01A",
		component: () => import("../views/components/outlineButton01AView/outlineButton01AView.vue"),
		meta: {
			title: "outlineButton01A",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/outlineButton01A/",
		},
	},
	{
		path: "/components/tonalButton01A/",
		name: "tonalButton01A",
		component: () => import("../views/components/tonalButton01AView/tonalButton01AView.vue"),
		meta: {
			title: "tonalButton01A",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/tonalButton01A/",
		},
	},
	{
		path: "/components/tonalButton01B/",
		name: "tonalButton01B",
		component: () => import("../views/components/tonalButton01BView/tonalButton01BView.vue"),
		meta: {
			title: "tonalButton01B",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/tonalButton01B/",
		},
	},
	{
		path: "/components/icon-button-01-a/",
		name: "IconButton01A",
		component: () => import("../views/components/iconButton01AView/iconButton01AView.vue"),
		meta: {
			title: "iconButton01A",
		},
	},
	{
		path: "/components/iconButton01B/",
		name: "IconButton01B",
		component: () => import("../views/components/iconButton01BView/iconButton01BView.vue"),
		meta: {
			title: "iconButton01B",
		},
	},
	{
		path: "/components/textButton01A/",
		name: "textButton01A",
		component: () => import("../views/components/textButton01AView/textButton01AView.vue"),
		meta: {
			title: "textButton01A",
		},
	},
	{
		path: "/components/chipButton01A/",
		name: "chipButton01A",
		component: () => import("../views/components/chipButton01AView/chipButton01AView.vue"),
		meta: {
			title: "chipButton01A",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/chipButton01A/",
		},
	},
	{
		path: "/components/text-button-02-a/",
		name: "textButton01B",
		component: () => import("../views/components/textButton01BView/textButton01BView.vue"),
		meta: {
			title: "textButton01B",
		},
	},

	/* Block Animations */
	{
		path: "/animations/image-trigger/",
		name: "image-trigger",
		component: () => import("@/views/animations/BlocksView/ImageTriggerView/ImageTriggerView.vue"),
		meta: {
			title: "Image Trigger",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/animations/ImageTrigger/",
		},
	},
	{
		path: "/animations/image-hover/",
		name: "image-hover",
		component: () => import("@/views/animations/BlocksView/ImageHoverView/ImageHoverView.vue"),
		meta: {
			title: "Image Hover",
			sourceLink: "",
		},
	},
	{
		path: "/components/banner-loop/",
		name: "banner-loop",
		component: () => import("@/views/animations/BlocksView/BannerLoopView/BannerLoopView.vue"),
		meta: {
			title: "Banner Loop",
			sourceLink: "",
			wrapper: "banner-loop",
		},
	},
	{
		path: "/animations/block-append/",
		name: "block-append",
		component: () => import("@/views/animations/BlocksView/BlockAppendView/BlockAppendView.vue"),
		meta: {
			title: "Block Append",
			sourceLink: "",
		},
	},
	{
		path: "/animations/loader-animation/",
		name: "loader-animation",
		component: () => import("@/views/animations/BlocksView/LoaderAnimationView/LoaderAnimationView.vue"),
		meta: {
			title: "Loader Animation",
			type: "Block Animation",
			description: "",
			sourceLink: "",
		},
	},

	/* Text Animations */
	{
		path: "/animations/append-header/",
		name: "append-header",
		component: () => import("@/views/animations/TextView/AppendHeaderView/AppendHeaderView.vue"),

		meta: {
			title: "Append Header",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/animations/AppendHeader/",
		},
	},
	{
		path: "/animations/append-header-transform/",
		name: "append-header-transform",
		component: () => import("@/views/animations/TextView/AppendHeaderTransformView/AppendHeaderTransformView.vue"),
		meta: {
			title: "Append Header Transform",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/animations/AppendHeaderTransform/",
		},
	},

	/* Page Transition */
	{
		path: "/animations/default-page-transition-one/",
		name: "default-page-transition-one",
		component: () =>
			import("@/views/animations/PageTransitions/DefaultPageTransitionView/DefaultPageTransitionView.vue"),
		meta: {
			title: "Default Page Transition",
			transition: "default",
			mode: "out-in",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/assets/styles/components/_default-transition.scss",
		},
	},
	{
		path: "/animations/default-page-transition-two/",
		name: "default-page-transition-two",
		component: () =>
			import("@/views/animations/PageTransitions/DefaultPageTransitionTwoView/DefaultPageTransitionTwoView.vue"),

		meta: {
			title: "Default Page Transition",
			transition: "default",
			mode: "out-in",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/assets/styles/components/_default-transition.scss",
		},
	},

	/* Splash Screens */
	{
		path: "/animations/overlay-splash-screen/",
		name: "overlay-splash-screen",
		component: () =>
			import("@/views/animations/SplashScreenView/OverlaySplashScreenView/OverlaySplashScreenView.vue"),
		meta: {
			title: "Overlay Splash Screen",
			sourceLink: "",
		},
	},

	/* UI */
	{
		path: "/animations/progress-bar/",
		name: "progress-bar",
		component: () => import("@/views/animations/UiView/ProgressBarView/ProgressBarView.vue"),
		meta: {
			title: "Progress Bar",
			wrapper: "progress-bar",
			sourceLink:
				"https://bitbucket.org/laitdevelopers/frontend-library/src/master/src/components/animations/ProgressBar/",
		},
	},
];

const router = createRouter({
	scrollBehavior: async function (to, from, savedPosition) {
		setTimeout(() => {
			if (savedPosition) {
				setTimeout(() => window.scrollTo(savedPosition.left, savedPosition.top));
				return;
			} else {
				setTimeout(() => window.scrollTo(0, 0));
				return;
			}
		}, 5);
	},
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: "active-link",
	linkExactActiveClass: "exact-active-link",
});

export default router;
