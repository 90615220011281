<template>
	<div class="show-case-wrapper">
		<slot></slot>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
