<template>
	<div class="background-color" :class="{ 'background-color--teal': getPaths }">
		<div class="app">
			<header class="header" :class="{ 'header--overview': getPaths }">
				<router-link to="/" class="header__logo">
					<LaitLogo />
				</router-link>
			</header>

			<OverviewLayout v-if="getPaths">
				<h1 class="header__h1">Library</h1>
				<div class="routers">
					<router-link class="routers__link" to="/" tabindex="0">
						<span>LAIT DSM</span>
						<span v-if="getDSMCount">{{ getDSMCount }}</span>
					</router-link>
					<router-link class="routers__link" to="/komponenter/" tabindex="0">
						<span>Komponenter</span>
						<span v-if="getComponentCount">{{ getComponentCount }}</span>
					</router-link>
					<router-link class="routers__link" to="/animations/" tabindex="0">
						<span>Animationer</span>
						<span v-if="getAnimationCount">{{ getAnimationCount }}</span>
					</router-link>
				</div>
				<router-view v-slot="{ Component, route }">
					<component
						:is="Component"
						:key="route.path"
						@dsmCount="getDSM"
						@componentCount="getComponent"
						@animationCount="getAnimation"
					/>
				</router-view>
			</OverviewLayout>

			<ShowcaseLayout v-else>
				<div class="area">
					<ShowcaseView>
						<router-view v-slot="{ Component, route }">
							<transition :name="route.meta.transition" :mode="'out-in'">
								<component :is="Component" :key="route.path" />
							</transition>
						</router-view>
					</ShowcaseView>
				</div>
			</ShowcaseLayout>
		</div>
	</div>
</template>

<script lang="ts">
import LaitLogo from "@/assets/icons/LaitLogo.vue";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import OverviewLayout from "./layouts/OverviewLayout.vue";
import ShowcaseLayout from "./layouts/ShowcaseLayout.vue";
import ShowcaseView from "./views/ShowcaseView.vue";

export default defineComponent({
	name: "App",
	components: {
		OverviewLayout,
		ShowcaseLayout,
		ShowcaseView,
		LaitLogo,
	},
	setup() {
		const route = useRoute();
		const path = computed(() => route.path);
		const getDSMCount = ref();
		const getComponentCount = ref();
		const getAnimationCount = ref();

		const getPaths = computed(
			() => path.value === "/" || path.value === "/animations/" || path.value === "/komponenter/"
		);

		function getBackLink() {
			if (path.value.includes("/animations")) {
				return "/animations/";
			} else if (path.value.includes("/komponenter")) {
				return "/komponenter/";
			} else {
				return "/";
			}
		}

		function getDSM(value: { value: number }) {
			getDSMCount.value = value.value;
		}

		function getComponent(value: { value: number }) {
			getComponentCount.value = value.value;
		}

		function getAnimation(value: { value: number }) {
			getAnimationCount.value = value.value;
		}

		return {
			path,
			getBackLink,
			getComponentCount,
			getAnimationCount,
			getDSMCount,
			getPaths,
			getComponent,
			getAnimation,
			getDSM,
		};
	},
});
</script>

<style lang="scss">
@import "@/assets/styles/showcase/layouts/_app.scss";
@import "./assets/styles/settings/fonts-showcase-setup";
</style>
