import {
	LaitAccordionItem,
	LaitDropdownSimple,
	baseButton01,
	baseLink01,
	contactCard01A,
	contactCard01B,
	contentCard01A,
	contentCard01B,
	textButton01A,
	tonalButton01A,
	tonalButton01B,
} from "@lait/lait-frontend-library";
import { createApp } from "vue";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import App from "./App.vue";
import router from "./router";
const app = createApp(App);
app.use(router).mount("#app");
app.use(VuePlyr, {
	plyr: {
		autoplay: true,
		loop: {
			active: true,
		},
		muted: true,
		controls: [],
	},
});
app.use(LaitAccordionItem);
app.use(LaitDropdownSimple);
app.use(baseButton01);
app.use(baseLink01);
app.use(tonalButton01A);
app.use(tonalButton01B);
app.use(textButton01A);
app.use(contentCard01A);
app.use(contentCard01B);
app.use(contactCard01A);
app.use(contactCard01B);
