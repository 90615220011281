<template>
	<div class="show-case" :class="data.wrapper ? `${data?.wrapper}-wrapper` : ''">
		<div class="show-case__top">
			<router-link :to="getBackLink" class="back-button">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 23" class="list-view__item-svg">
						<path
							d="M531.761934,115.991841 L519.574443,105.210588 C519.257035,104.929804 518.742876,104.929804 518.425544,105.210588 C518.108136,105.491372 518.108136,105.946207 518.425544,106.226923 L529.226098,115.781269 L493.812525,115.781269 C493.363416,115.781269 493,116.102753 493,116.500042 C493,116.897331 493.363416,117.218815 493.812525,117.218815 L529.226098,117.218815 L518.425544,126.773093 C518.108136,127.053878 518.108136,127.508712 518.425544,127.789429 C518.58421,127.929787 518.792159,128 519.000032,128 C519.207905,128 519.415778,127.929787 519.57452,127.789429 L531.762011,117.008176 C532.079342,116.727459 532.079342,116.272625 531.761934,115.991841 Z"
							transform="translate(-493 -105)"
						></path>
					</svg>
				</span>
				<span class="back-button__text"> Tilbage til oversigt</span>
			</router-link>

			<div class="show-case__code-snippet">
				<button
					@click="toggleModal()"
					class="show-case__code"
					v-if="data.sourceLink"
					:href="data.sourceLink"
					target="_blank"
				>
					<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
							stroke="#A3C8D1"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M11 7V11"
							stroke="#A3C8D1"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M11 15H11.01"
							stroke="#A3C8D1"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<span>Kode eksempel</span>
				</button>
			</div>
		</div>

		<div class="show-case__wrapper">
			<div class="show-case__teaser">
				<h1 class="show-case__header">
					<span v-if="data.title"> {{ data.title }} </span>
					<span v-else-if="!data.title && path.includes('/animations')"> Animation </span>
					<span v-else> Komponent </span>
				</h1>
			</div>
			<div class="show-case__content">
				<slot></slot>
			</div>
		</div>

		<teleport to="body">
			<div v-if="modalActive" class="code-modal">
				<div class="code-modal__content">
					<button class="code-modal__close-btn" @click="toggleModal()">
						<svg id="icon__close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
							<path
								d="M445.90625,422.90625 L434.09375,422.90625 L434.09375,411.09375 C434.09375,410.489672 433.604078,410 433,410 C432.395922,410 431.90625,410.489672 431.90625,411.09375 L431.90625,422.90625 L420.09375,422.90625 C419.489672,422.90625 419,423.395922 419,424 C419,424.604078 419.489672,425.09375 420.09375,425.09375 L431.90625,425.09375 L431.90625,436.90625 C431.90625,437.510328 432.395922,438 433,438 C433.604078,438 434.09375,437.510328 434.09375,436.90625 L434.09375,425.09375 L445.90625,425.09375 C446.510328,425.09375 447,424.604078 447,424 C447,423.395922 446.510328,422.90625 445.90625,422.90625 Z"
								transform="rotate(45 720.535 -291.9)"
							/>
						</svg>
					</button>
					<h3>Kode eksempel kommer snart</h3>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	components: {},
	setup() {
		const route = useRoute();
		const path = computed(() => route.path);
		const data = route.meta as RouteMeta;
		const modalActive = ref(false);

		const getBackLink = computed(() => {
			if (path.value.includes("/animations")) {
				return "/animations/";
			} else {
				return "/";
			}
		});

		const toggleModal = () => {
			modalActive.value = !modalActive.value;
		};

		interface RouteMeta {
			wrapper?: string;
			title?: string;
			type?: string;
			description?: string;
			sourceLink?: string;
		}

		return {
			path,
			route,
			data,
			getBackLink,
			modalActive,
			toggleModal,
		};
	},
});
</script>

<style lang="scss" src="@/assets/styles/showcase/layouts/_show-case.scss"></style>
