<template>
	<main class="overview-wrapper">
		<slot></slot>
	</main>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss">
@import "@/assets/styles/settings/vars";
@import "@/assets/styles/settings/fonts";

.overview-wrapper {
	color: $color-white;
	background-color: $color-teal;
	padding: 0;
	position: relative;
	height: 100%;
	margin: 0 auto;
	width: 100%;
}
</style>
